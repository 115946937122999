import React, { useState } from 'react'
import './method.css'

const Process = ({ sectionRef }) => {
  const [isMiddleTextOne, setIsMiddleTextOne] = useState(false)
  const [isMiddleTextTwo, setIsMiddleTextTwo] = useState(false)
  const [isMiddleTextThree, setIsMiddleTextThree] = useState(false)

  const [isDetailTextOne, setIsDetailTextOne] = useState(false)
  const [isDetailTextTwo, setIsDetailTextTwo] = useState(false)
  const [isDetailTextThree, setIsDetailTextThree] = useState(false)

  const handleMiddleText = (setTextState, setDetailTextState) => {
    setTextState((prev) => !prev)
    setDetailTextState(false)
  }

  const handleShowDetailText = (middleTextState, setDetailTextState) => {
    if (!middleTextState) {
      setDetailTextState(true)
    }
  }

  const handleRemoveDetailText = (setDetailTextState) => {
    setDetailTextState(false)
  }

  return (
    <div className={`method `} id="method" ref={sectionRef}>
      <div
        className={`method__content  ${isMiddleTextOne && 'active'}`}
        onClick={() => handleMiddleText(setIsMiddleTextOne, setIsDetailTextOne)}
        onMouseOver={() =>
          handleShowDetailText(isMiddleTextOne, setIsDetailTextOne)
        }
        onMouseLeave={() => handleRemoveDetailText(setIsDetailTextOne)}
      >
        <div className="method__content--header">
          <p>Definition & Discovery</p>
        </div>
        <div className={`method__content__middle`}>
          {isDetailTextOne && (
            <div className="content__middle--detail">
              <p>View Details</p>
            </div>
          )}
          {isMiddleTextOne && (
            <>
              <div className="method__content--description">
                <p>
                  We define the role together by clarifying essential
                  qualifications versus desirable traits to ensure alignment
                  with necessary skills and attributes. Then, a calibration
                  meeting is held with the leadership team to review and confirm
                  target profiles. This will result in an outlined hiring
                  process, developed screening questions, and established
                  effective communication strategy.
                </p>
              </div>
              <div className="method__content--service">
                <li>In-depth candidate framing meeting</li>
                <li>Talent profile calibration</li>
                <li>Brand messaging alignment</li>
                <li>Weekly target list building</li>
              </div>
            </>
          )}
        </div>
        <div className="method__content--number numberOne">
          <div className="method--square" />
          <p>1 /</p>
        </div>
      </div>

      <div
        className={`method__content  ${isMiddleTextTwo && 'active'}`}
        onClick={() => handleMiddleText(setIsMiddleTextTwo, setIsDetailTextTwo)}
        onMouseOver={() =>
          handleShowDetailText(isMiddleTextTwo, setIsDetailTextTwo)
        }
        onMouseLeave={() => handleRemoveDetailText(setIsDetailTextTwo)}
      >
        <div className="method__content--header">
          <p>Target & Execution</p>
        </div>
        <div className="method__content__middle">
          {isDetailTextTwo && (
            <div className="content__middle--detail">
              <p>View Details</p>
            </div>
          )}
          {isMiddleTextTwo && (
            <>
              <div className="method__content--description">
                <p>
                  We use innovative talent acquisition strategies to secure
                  high-quality candidates, and start sourcing and targeting
                  efforts through multiple channels. This will result in a
                  talent dashboard that effectively communicates progress to
                  clients.
                </p>
              </div>
              <div className="method__content--service">
                <li>Multi-channel talent engagement and recruitment</li>
                <li>Talent ‘fit’ screenings</li>
                <li>Screening notes and recommendations</li>
                <li>Talent process and logistic managemen</li>
              </div>
            </>
          )}
        </div>
        <div className="method__content--number numberTwo">
          <div className="method--square" />

          <p>2 /</p>
        </div>
      </div>

      <div
        className={`method__content  ${isMiddleTextThree && 'active'}`}
        onClick={() =>
          handleMiddleText(setIsMiddleTextThree, setIsDetailTextThree)
        }
        onMouseOver={() =>
          handleShowDetailText(isMiddleTextThree, setIsDetailTextThree)
        }
        onMouseLeave={() => handleRemoveDetailText(setIsDetailTextThree)}
      >
        <div className="method__content--header">
          <p>Build & Support</p>
        </div>
        <div className="method__content__middle">
          {isDetailTextThree && (
            <div className="content__middle--detail">
              <p>View Details</p>
            </div>
          )}
          {isMiddleTextThree && (
            <>
              <div className="method__content--description">
                <p>
                  We tailor talent dashboards to meet clients' unique needs,
                  offering visibility into project progress. Consistent meetings
                  with hiring managers and client leadership teams to review
                  project performance, analyze metrics, and provide guidance on
                  situational decisions regarding candidates in the interview
                  process.
                </p>
              </div>
              <div className="method__content--service">
                <li> Project health reviews</li>
                <li>Project strategy retro and discussion</li>
                <li>ATS upkeep and management</li>
              </div>
            </>
          )}
        </div>
        <div className="method__content--number numberThree">
          <div className="method--square" />
          <p>3 /</p>
        </div>
      </div>
    </div>
  )
}

export default Process
