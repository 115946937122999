import './loading.css'
import React from 'react'

const Loading = () => {
  return (
    <div className={`loading__container`}>
      <svg
        width="120"
        height="25"
        viewBox="0 0 120 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="loading__svg"
      >
        <g clipPath="url(#clip0_518_85)">
          <path
            className="loading__logo--letter"
            style={{ '--letter': 1 }}
            d="M0 24.4863V0.513672H8.62028C15.7302 0.513672 20.6958 5.37954 20.6958 12.3644C20.6958 19.3493 15.7374 24.4863 8.62028 24.4863H0ZM4.10354 20.7905H8.891C13.3365 20.7905 16.4498 17.3302 16.4498 12.3644C16.4498 7.66978 13.3365 4.21658 8.891 4.21658H4.10354V20.7905Z"
            fill="white"
          />
          <path
            className="loading__logo--letter"
            style={{ '--letter': 2 }}
            d="M25.2695 24.4863V0.513672H42.0256V4.20945H29.3374V10.3738H40.8288V13.9697H29.3374V20.7834H42.332V24.4791H25.2695V24.4863Z"
            fill="white"
          />
          <path
            className="loading__logo--letter"
            style={{ '--letter': 3 }}
            d="M54.1011 24.4863L44.7969 0.513672H49.2068L56.2526 18.8713L63.0562 0.513672H67.4305L58.4041 24.4863H54.094H54.1011Z"
            fill="white"
          />
          <path
            className="loading__logo--letter"
            style={{ '--letter': 4 }}
            d="M64.1748 24.4863L73.2012 0.513672H77.5754L68.5491 24.4863H64.1748Z"
            fill="white"
          />
          <path
            className="loading__logo--letter"
            style={{ '--letter': 5 }}
            d="M78.858 17.0878H83.0613C83.4389 19.692 85.8326 21.333 89.6583 21.333C92.7716 21.333 94.4101 20.1343 94.4101 18.1152C94.4101 16.2316 92.9425 15.4754 90.6129 14.7619L86.716 13.5989C83.9091 12.7428 79.5348 11.5085 79.5348 6.82097C79.5348 2.88261 82.7834 0.00732422 88.0838 0.00732422C93.8616 0.00732422 97.559 3.12519 97.8654 8.19083H93.6906C93.5196 5.48678 91.5391 3.66743 87.9414 3.66743C85.3054 3.66743 83.5956 4.76617 83.5956 6.61406C83.5956 8.70453 85.683 9.21823 88.0055 9.9745L91.6317 11.1018C95.0157 12.1649 98.5778 13.3992 98.5778 17.9154C98.5778 22.4317 95.1225 25.0073 89.6512 25.0073C83.3605 25.0073 79.1501 21.8538 78.8438 17.0949L78.858 17.0878Z"
            fill="white"
          />
          <path
            className="loading__logo--letter"
            style={{ '--letter': 6 }}
            d="M102.931 24.4863V0.513672H119.687V4.20945H106.999V10.3738H118.49V13.9697H106.999V20.7834H119.993V24.4791H102.931V24.4863Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_518_85">
            <rect width="120" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default Loading
