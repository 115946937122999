// update sept 2023
export const roleData = [
  {
    name: 'CEO/COO',
    stage: 'Biotech / Early Stage'
  },
  {
    name: 'Board Member',
    stage: 'Fintech / Growth Stage'
  },
  {
    name: 'Vice President, Engineering',
    stage: 'PropTech & Real Estate / Growth Stage'
  },
  {
    name: '(Founding) Head of Product',
    stage: 'HealthTech / Early Stage'
  },
  {
    name: 'Senior Software Engineer (Front-End)',
    stage: 'Insur Tech / Growth Stage'
  },
  {
    name: 'Software Engineer, AI/ML',
    stage: 'AI/ML & Biotechnology Research / Early Stage'
  },
  {
    name: 'Senior Machine Learning Engineer',
    stage: 'AI/ML & Biotechnology Research / Early Stage'
  },
  {
    name: 'Lead Designer',
    stage: 'Gaming / Early Stage'
  },
  {
    name: 'Enterprise Account Executive',
    stage: 'EdTech / Growth Stage'
  },
  {
    name: 'Chief Revenue Officer',
    stage: 'PropTech & Real Estate / Growth Stage'
  },
  {
    name: 'Strategic Finance & Business Operations',
    stage: 'Healthtech B2B2C / Early Stage'
  },
  {
    name: 'Controller',
    stage: 'InsurTech / Growth Stage'
  },
  {
    name: 'CMO/Head of Marketing',
    stage: 'Venture Capital / Portfolio in various stages'
  },
  {
    name: 'Performance Marketing Manager',
    stage: 'DTC Food Services / Growth Stage'
  },
  {
    name: 'Talent & People Operations Manager',
    stage: 'Non-Profit AI/ML Research Services'
  },
  {
    name: 'Product Development Manager',
    stage: 'Innovation, Food, Sustainability / Early Stage'
  }
]
