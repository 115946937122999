const locations = [
  'United States',
  'Phoenix',
  'San Francisco',
  'Los Angeles',
  'New York City',
  'Boston',
  'Austin',
  'Miami',
  'Seattle',
  'Salt Lake City',
  'Tel Aviv',
  'London',
  'Berlin',
  'Warsaw',
  'Munich',
  'Montreal',
  'Kraków'
]

export default locations
