import images from '../assets/carousel/index.js'
import logos from '../assets/logos/companies/index.js'

const companyList = [
  {
    Innovation: [
      {
        logo: logos['solari'],
        subText: 'Venture Capital',
        imgUrl: images['solari'],
        name: 'Solari Captial',
        url: 'https://www.solaricap.com/',
        liner:
          'An investment firm that builds and invests in pioneering companies programming reality for an abundant tomorrow.'
      },
      {
        logo: logos['kairos'],
        subText: 'Venture Capital',
        imgUrl: images['kairos'],
        name: 'Kairos',
        url: 'https://www.kairoshq.com/',
        liner:
          'Investing in brands that focuses on making life simpler and more affordable for our generation'
      },
      {
        logo: logos['roadrunnerVentureStudios'],
        subText: 'Venture Capital, DeepTech',
        imgUrl: images['roadrunnerVentureStudios'],
        name: 'Roadrunner Venture Studios',
        url: 'https://roadrunnerventurestudios.com/',
        liner:
          'Nation’s platform for founding breakthrough ventures and leading the next technology revolution'
      },
      {
        logo: logos['salt'],
        subText: 'Biotech, Crypto, CPG',
        imgUrl: images['salt'],
        name: 'Salt',
        url: 'https://www.salt.org/',
        liner:
          'Thought leadership and networking forum that encompasses finance, technology and public policy'
      },

      {
        logo: logos['lowerCarbonCapital'],
        subText: 'Venture Capital, ClimateTech',
        imgUrl: images['lowerCarbonCapital'],
        name: 'Lowercarbon Capital',
        url: 'https://lowercarboncapital.com/',
        liner:
          'Investing in companies that make real money by making positive changes to our planet.'
      },

      {
        logo: logos['navigate'],
        subText: 'Management Consulting,  Sports industry',
        imgUrl: images['navigate'],
        name: 'Navigate',
        url: 'https://nvgt.com/',
        liner:
          'Guiding strategies and decisions for the largest leagues, teams, brands, networks, and universities in the world.'
      }
    ]
  },
  {
    NonProfitOrganization: [
      {
        logo: logos['giftCardBank'],
        subText: 'NPO',
        imgUrl: images['giftCardBank'],
        name: 'Gift Card Bank',
        url: 'https://www.giftcardbank.org/',
        liner:
          'Matching gift card donations for living essentials to people who are on the brink of financial duress.'
      },
      {
        logo: logos['veganFriendly'],
        subText: 'NPO',
        imgUrl: images['veganFriendly'],
        name: 'Vegan Friendly',
        url: 'https://vegan-friendly.co.uk/',
        liner:
          'Non-profit organization aiming to end animal suffering and global warming by increasing the consumption of plant-based food.'
      }
    ]
  },
  {
    AI: [
      {
        logo: logos['futureHouse'],
        subText: 'AI/ML, Research Services',
        imgUrl: images['futureHouse'],
        name: 'Future House',
        url: 'https://www.futurehouse.org/',
        liner:
          'Building an AI Scientist that automates scientific research, accelerates discovery and provides access to cutting-edge scientific, medical, and engineering expertise.'
      },
      {
        logo: logos['insitro'],
        subText: 'Biotechnology Research',
        imgUrl: images['insitro'],
        name: 'insitro',
        url: 'https://insitro.com/',
        liner:
          'The convergence of machine learning and biology at scale enabling better medicines for patients.'
      },
      {
        logo: logos['panacentBio'],
        subText: 'Biotechnology Research',
        imgUrl: images['panacentBio'],
        name: 'Panacent Bio',
        url: 'https://panacent.bio/',
        liner: 'Precision Biology Redefined. Coming Soon.'
      }
    ]
  },
  {
    InsurTech: [
      {
        logo: logos['reserv'],
        subText: 'Claims, Data Intelligence',
        imgUrl: images['reserv'],
        name: 'Reserv',
        url: 'https://www.reserv.com/',
        liner:
          'Powering the entire claims ecosystem with solution-first products and services that deliver better outcomes.'
      },
      {
        logo: logos['rhino'],
        subText: 'InsurTech',
        imgUrl: images['rhino'],
        name: 'Rhino',
        url: 'https://www.sayrhino.com/',
        liner:
          'Modernizing renting by solving renters’ biggest financial problems, starting with cash security deposit.'
      }
    ]
  },
  {
    HealthTech: [
      {
        logo: logos['radial'],
        subText: 'HealthTech',
        imgUrl: images['radial'],
        name: 'Radial',
        url: 'https://www.meetradial.com/',
        liner:
          'Providing affordable and innovative mental treatment from anywhere.'
      },
      {
        logo: logos['duet'],
        subText: 'HealthTech',
        imgUrl: images['duet'],
        name: 'Duet',
        url: 'http://joinduet.com/',
        liner:
          'Empowering nurse practitioners to thrive as practice owners and transform access to primary care.'
      }
    ]
  },
  {
    FinTech: [
      {
        logo: logos['sorbet'],
        subText: 'Consumer Lending',
        imgUrl: images['sorbet'],
        name: 'Sorbet',
        url: 'http://getsorbet.com/',
        liner:
          'Advance the cash value of your unused PTO and unlock extra compensation without dipping into your paycheck.'
      },
      {
        logo: logos['ionicDigital'],
        subText: 'Digital Assets, Bitcoin Mining',
        imgUrl: images['ionicDigital'],
        name: 'ionic Digital',
        url: 'https://ionicdigital.com/',
        liner:
          'Innovative gaming studio bringing free mobile games to market that is fun first, quality design, and community focused'
      },
      // {
      //   logo: logos['hut8'],
      //   subText: 'Crypto, Digital Assets, Bitcoin Mining',
      //   imgUrl: images['hut8'],
      //   name: 'Hut 8',
      //   url: 'http://www.hut8.com/',
      //   liner:
      //     'Driving innovation while supporting the digital economy through high performance computing (HPC) infrastructure, cutting-edge technology solutions, and digital asset mining.'
      // },
      {
        logo: logos['kaiizen'],
        subText: 'PropTech',
        imgUrl: images['kaiizen'],
        name: 'Kaiizen',
        url: 'https://www.kaiizen.com/',
        liner:
          'Revolutionizing off-market SFR for investors by offering unparalleled intelligence to uncover hidden opportunities, streamline transactions, and maximize returns.'
      },
      {
        logo: logos['bilt'],
        subText: 'PropTech',
        imgUrl: images['bilt'],
        name: 'BILT Rewards',
        url: 'https://www.biltrewards.com/',
        liner:
          'Rewards program that allows renters to earn points on rent and create a path towards homeownership.'
      }
    ]
  },
  {
    Gaming: [
      {
        logo: logos['sbGames'],
        subText: 'Mobile Gaming',
        imgUrl: images['sbGames'],
        name: 'SB Games',
        url: 'https://sbgames.in/',
        liner:
          'Innovative gaming studio bringing free mobile games to market that is fun first, quality design, and community focused.'
      }
    ]
  },
  {
    EdTech: [
      {
        logo: logos['engageli'],
        subText: 'Learning and Development, Video-Tech Platform',
        imgUrl: images['engageli'],
        name: 'Engageli',
        url: 'https://www.engageli.com/',
        liner:
          'Inclusive digital learning & development platform that creates superior teaching and learning experiences.'
      },
      {
        logo: logos['growthspace'],
        subText: 'Learning and Development, Employee Growth',
        imgUrl: images['growthspace'],
        name: 'Growthspace',
        url: 'https://www.growthspace.com/',
        liner:
          'One stop shop for human-to-human employee development through coaching, mentoring, training, and more solutions'
      }
    ]
  },
  {
    Food: [
      {
        logo: logos['plonts'],
        subText: 'Food, Sustainability',
        imgUrl: images['plonts'],
        name: 'Plonts',
        url: 'https://www.plonts.com/',
        liner:
          'Discovers and designs microbial communities to transform inexpensive plants into new categories of sustainable fermented foods.'
      },
      {
        logo: logos['minusCoffee'],
        subText: 'Food, Sustainability',
        imgUrl: images['minusCoffee'],
        name: 'Minus Coffee',
        url: 'https://shopminuscoffee.com/',
        liner:
          'Obsessed with coffee and the planet, a sustainable coffee leader is spearheading the beanless-coffee generation.'
      },
      {
        logo: logos['littleSpoon'],
        subText: 'DTC, eCommerce, Health & Wellness',
        imgUrl: images['littleSpoon'],
        name: 'Little Spoon',
        url: 'https://www.littlespoon.com/',
        liner:
          "Designing products for a child's most formative years, starting with a baby's first bites."
      },
      {
        logo: logos['tomorrowFarms'],
        subText: 'Sustainability, Innovations',
        imgUrl: images['tomorrowFarms'],
        name: 'Tomorrow Farms ',
        url: 'https://www.tomorrowfarms.co/',
        liner:
          'Building irresistible food and beverage brands that are better for people, kinder to animals, and easier on the planet.'
      }
    ]
  },
  {
    SaaS: [
      {
        logo: logos['linkSquares'],
        subText: 'SaaS, LegalTech, Contract Management using AI',
        imgUrl: images['linkSquares'],
        name: 'LinkSquares ',
        url: 'https://linksquares.com/',
        liner:
          'AI-powered contracting platform for legal teams that streamlines management processes to close deals at warp speed.'
      },
      {
        logo: logos['petPocketBook'],
        subText: 'SaaS, Petcare Services',
        imgUrl: images['petPocketBook'],
        name: 'PetPocketbook ',
        url: 'https://www.petpocketbook.com/',
        liner:
          'Automate billing, streamline scheduling, and stay organized with everything you need in a single platform.'
      }
    ]
  },
  {
    PropTech: [
      {
        logo: logos['radix'],
        subText: 'Multi-Family SaaS Property Management',
        imgUrl: images['radix'],
        name: 'Radix ',
        url: 'https://www.radix.com/',
        liner:
          'Promoting transparency across the multifamily market by offering equal access to comprehensive data for all stakeholders.'
      }
    ]
  },
  {
    BioTech: [
      {
        logo: logos['inaedis'],
        subText: 'Research',
        imgUrl: images['inaedis'],
        name: 'Inaedis ',
        url: 'https://inaedis.com/',
        liner:
          'Unchaining the pharmaceutical cold chain with high-quality powdered biologics and vaccines.'
      }
    ]
  },
  {
    Tech: [
      {
        logo: logos['akamai'],
        subText: 'Global Technology Company',
        imgUrl: images['akamai'],
        name: 'Akamai Technologies',
        url: 'https://www.akamai.com/',
        liner:
          'Cloud services provider that help enterprises provide secure, high-performing user experiences on any device.'
      },
      {
        logo: logos['sfara'],
        subText: 'Mobile and Software Product, AI/ML',
        imgUrl: images['sfara'],
        name: 'Sfara',
        url: 'https://www.sfara.com/',
        liner:
          'Universal safety solution for people in various stages of life and industries of automotive, healthcare, higher education, employee benefits, and many more.'
      }
    ]
  }
]

const companyCategory = [
  'Innovation',
  'Non Profit Organization',
  'AI',
  'InsurTech',
  'HealthTech',
  'FinTech',
  'Gaming',
  'EdTech',
  'Food',
  'SaaS',
  'PropTech',
  'BioTech',
  'Tech'
]

export { companyList, companyCategory }
