import './App.css'
import React, { useRef, useState, useEffect } from 'react'
import Navbar from './components/navbar/Navbar'
import {
  Contact,
  ClientCarousel,
  Method,
  Roles,
  Hero,
  LogoMarquee,
  AboutUs
} from './containers'
import Loading from './components/loading/Loading'

const App = () => {
  const sectionRef = useRef([...Array(5)].map((_) => React.createRef()))

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(loadingTimer)
  }, [])

  return (
    <div className={`App`}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Navbar sectionRef={sectionRef} />
          <Hero />
          <LogoMarquee />
          <AboutUs sectionRef={sectionRef.current[0]} />
          <Method sectionRef={sectionRef.current[1]} />
          <ClientCarousel sectionRef={sectionRef.current[2]} />
          <Roles sectionRef={sectionRef.current[3]} />
          <Contact sectionRef={sectionRef.current[4]} />
        </>
      )}
    </div>
  )
}

export default App
