import React, { useEffect, useRef, useState } from 'react'

import { companyList, companyCategory } from '../../constants/companies'
import linkArrow from '../../assets/arrows/link_arrow.svg'

import './clientCarousel.css'

const ClientCarousel = ({ sectionRef }) => {
  const imgRef = useRef([])
  const carouselRef = useRef(null)
  const footerRef = useRef(null)
  const [active, setActive] = useState('')
  const [activeCate, setActiveCate] = useState('Innovation')
  const [companyDetail, setCompanyDetail] = useState([])
  const [mouseOnCarousel, setMouseOnCarousel] = useState('')

  const handleCarouselScroll = (data) => {
    setActiveCate(data)
    data = data.replace(/ /g, '')
    const showImage = document?.querySelector(`#${data}`)
    showImage.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest'
    })
  }
  const options = {
    roots: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: [...Array(20)].map((_, idx) => idx * 0.05)
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // if carousel image is showing 100%. do something
        if (entry.intersectionRatio > 0.8) {
          entry.target.querySelector('div').style.opacity = 1
          const index = parseInt(entry.target.getAttribute('data-index'))
          const compName = entry.target.getAttribute('data-name')
          const cate = entry.target.getAttribute('data-category')
          setActiveCate(cate)
          setActive(entry.target.childNodes[0].getAttribute('id'))
          setCompanyDetail(companyList[index][cate][compName])
        } else {
          // hide not showing image text

          entry.target.querySelector('div').style.opacity = 0
        }
      })
    }, options)

    imgRef?.current.forEach((ref) => {
      if (ref) observer.observe(ref)
    })
  })

  useEffect(() => {
    // 👇️ get global mouse coordinates
    const handleMouseMove = (event) => {
      setMouseOnCarousel({
        x: event.screenX,
        y: event.screenY
      })
      if (
        event.screenX > carouselRef.current.getBoundingClientRect().left &&
        event.screenX <
          carouselRef.current.getBoundingClientRect().left * 1.4 &&
        event.screenY > carouselRef.current.getBoundingClientRect().top &&
        event.screenY < carouselRef.current.getBoundingClientRect().bottom
      ) {
        setMouseOnCarousel('left')
      } else if (
        event.screenX >
          carouselRef.current.getBoundingClientRect().right * 0.9 &&
        event.screenX < carouselRef.current.getBoundingClientRect().right &&
        event.screenY > carouselRef.current.getBoundingClientRect().top &&
        event.screenY < carouselRef.current.getBoundingClientRect().bottom
      ) {
        setMouseOnCarousel('right')
      } else if (
        event.screenX > carouselRef.current.getBoundingClientRect().x * 1.4 &&
        event.screenX <
          carouselRef.current.getBoundingClientRect().right * 0.9 &&
        event.screenY > carouselRef.current.getBoundingClientRect().top &&
        event.screenY < carouselRef.current.getBoundingClientRect().bottom
      ) {
        setMouseOnCarousel('center')
      }
    }
    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  })

  // if carousel showing half dock footer too bottom
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry.boundingClientRect.top > 0 && entry.isIntersecting) {
        footerRef.current.style.opacity = 1
      } else {
        footerRef.current.style.opacity = 0
      }
    }, options)

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }
  })

  const handleCarouselClick = () => {
    if (mouseOnCarousel === 'left') {
      carouselRef.current.scrollLeft -=
        carouselRef.current.childNodes[0].getBoundingClientRect().width
    } else if (mouseOnCarousel === 'right') {
      carouselRef.current.scrollLeft +=
        carouselRef.current.childNodes[0].getBoundingClientRect().width
    }
  }

  return (
    <div className="clientCarousel" ref={sectionRef} id="clients">
      <div className="clientCarousel__container">
        {/* selection list */}
        <div className="clientCarousel__selector">
          <div className="clientCarousel__selector--list">
            <div className="selector__list--header">
              <p>Industry</p>
            </div>
            {companyCategory.map((cate, index) => (
              <div
                onMouseEnter={() => handleCarouselScroll(cate)}
                className={`selector__list ${
                  active.toLowerCase() === cate.replace(/ /g, '').toLowerCase()
                    ? 'active'
                    : ''
                }`}
                key={index}
              >
                <p className="p__ppmori">{cate === 'AI' ? 'AI/ML' : cate}</p>
                <div />
              </div>
            ))}
          </div>
        </div>

        {/* carousel image */}
        <div className="clientCarousel__carousel">
          <div className="clientCarousel__carousel--label">
            <p>{activeCate === 'AI' ? 'AI/ML' : activeCate}</p>
          </div>
          <div
            className={`clientCarousel__carousel--box ${
              mouseOnCarousel === 'left' ? 'mouseLeft' : ''
            }
            ${mouseOnCarousel === 'right' ? 'mouseRight' : ''}
            ${mouseOnCarousel === 'center' ? 'mouseCenter' : ''}`}
            ref={carouselRef}
            onClick={handleCarouselClick}
          >
            {companyList.map((list, listIndex) =>
              Object.entries(list).map((el) =>
                el[1].map((comp, index) => (
                  <div
                    className="carouselCard"
                    data-category={el[0]}
                    data-name={index}
                    data-index={listIndex}
                    key={index}
                    ref={(comp) => {
                      imgRef.current.push(comp)
                      imgRef.current = [...new Set(imgRef.current)]
                    }}
                  >
                    <img
                      className="carouselCard--img"
                      src={comp.imgUrl}
                      alt={comp.name + index}
                      id={el[0]}
                    />

                    <div className="carouselCard--text-liner">
                      <img
                        className="carouselCard--logo"
                        src={comp.logo}
                        alt="logo"
                      />
                      <p className="p__ppmori">{comp.liner}</p>
                    </div>
                  </div>
                ))
              )
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="clientCarousel__footer" ref={footerRef}>
        <div className="clientCarousel__footer--square">
          <div className="" />
          <p>{companyDetail?.name}</p>
        </div>
        <div className="clientCarousel__footer--content">
          <p className="p__ppmori">
            {companyDetail.subText}
            <span>•</span>
          </p>
          <a href={companyDetail.url} target="_blank" rel="noreferrer noopener">
            Learn More
          </a>
          <img src={linkArrow} alt="arrow" />
        </div>
      </div>
    </div>
  )
}

export default ClientCarousel
