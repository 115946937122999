const logos = {}

const importAll = (fileLogos) => {
  fileLogos.keys().forEach((key) => {
    const logoName = key.replace('./', '').split('.')[0]
    logos[logoName] = fileLogos(key)
  })
}

importAll(require.context('./', false, /\.(png|jpe?g|svg|gif)$/))

export default logos
