import React, { useEffect, useRef } from 'react'

import './roles.css'
import { roleData } from '../../constants/roles'

const Roles = ({ sectionRef }) => {
  const contentRef = useRef(
    [...Array(roleData.length)].map((_) => React.createRef())
  )

  useEffect(() => {
    const handleHover = (index) => {
      contentRef?.current.forEach((ref, idx) => {
        if (index !== idx) {
          ref.current.style.color = 'gray'
        } else {
          ref.current.style.color = 'white'
          ref.current.style.borderBottom = '1px solid white'
        }
      })
    }
    const handleUnhover = () => {
      contentRef?.current.forEach((ref) => {
        ref.current.style.color = 'white'
        ref.current.style.borderBottom = '1px solid #3F3E3E'
      })
    }

    contentRef?.current.forEach((ref, index) => {
      ref.current.addEventListener('mouseover', () => handleHover(index))
      ref.current.addEventListener('mouseleave', handleUnhover)
    })
  }, [])

  return (
    <div className="roles" id="openRoles" ref={sectionRef}>
      <div className="roles__headline">
        <p className="roles__headline--role">Roles</p>
        <p className="roles__headline--header">
          Various roles we are currently working on
        </p>
      </div>
      <div className="roles__container">
        {roleData.map((data, index) => (
          <div
            className="roles__content"
            key={index + data.name}
            ref={contentRef.current[index]}
          >
            <div className="roles__content--container">
              <p className="roles__content--name">{data.name}</p>
              <p className="roles__content--stage">{data.stage}</p>
            </div>
            <div></div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Roles
