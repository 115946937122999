import logos from '../assets/logos/companies'

export const logoMarquee = [
  { imgUrl: logos['akamai'], subText: '' },
  { imgUrl: logos['bilt'], subText: '' },
  { imgUrl: logos['duet'], subText: '' },
  { imgUrl: logos['engageli'], subText: '' },
  { imgUrl: logos['futureHouse'], subText: '' },
  { imgUrl: logos['giftCardBank'], subText: '' },
  { imgUrl: logos['growthspace'], subText: '' },
  // { imgUrl: logos['hut8'], subText: '' },
  { imgUrl: logos['inaedis'], subText: '' },
  { imgUrl: logos['insitro'], subText: '' },
  { imgUrl: logos['ionicDigital'], subText: '' },
  { imgUrl: logos['kaiizen'], subText: '' },
  { imgUrl: logos['kairos'], subText: '' },
  { imgUrl: logos['linkSquares'], subText: '' },
  { imgUrl: logos['littleSpoon'], subText: '' },
  { imgUrl: logos['lowerCarbonCapital'], subText: '' },
  { imgUrl: logos['minusCoffee'], subText: '' },
  { imgUrl: logos['navigate'], subText: '' },
  { imgUrl: logos['panacentBio'], subText: '' },
  { imgUrl: logos['petPocketBook'], subText: '' },
  { imgUrl: logos['plonts'], subText: '' },
  { imgUrl: logos['radial'], subText: '' },
  { imgUrl: logos['radix'], subText: '' },
  { imgUrl: logos['reserv'], subText: '' },
  { imgUrl: logos['rhino'], subText: '' },
  { imgUrl: logos['roadrunnerVentureStudios'], subText: '' },
  { imgUrl: logos['salt'], subText: '' },
  { imgUrl: logos['sbGames'], subText: '' },
  { imgUrl: logos['solari'], subText: '' },
  { imgUrl: logos['sorbet'], subText: '' },
  { imgUrl: logos['tomorrowFarms'], subText: '' },
  { imgUrl: logos['veganFriendly'], subText: '' }
]
