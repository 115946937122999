import './AboutUs.css'
import React, { useRef } from 'react'

const AboutUs = ({ sectionRef }) => {
  return (
    <div className="aboutUs__container" id="about" ref={sectionRef}>
      <div className="aboutUs__headline">
        <p className="headline--about">About Us</p>
        <p className="aboutUs__headline--p">
          We help start-ups and scaling teams find great talent at all levels in
          every stage of growth.
        </p>
      </div>
      <div className="aboutUs__text__container">
        <p className="aboutUs__text--p first_para">
          Since 2021, Devise has filled countless roles of all kinds, at all
          levels, for innovative high-growth startups worldwide.
        </p>
      </div>
      <div className="aboutUs__text__container">
        <p className="aboutUs__text--p second_para">
          Our unique recruiting model and on-demand customizable solutions allow
          you to build incredible teams that are flexible, transparent, and
          cost-effective.
        </p>
      </div>
      <div className="aboutUs__text__container">
        <p className="aboutUs__text--p third_para">
          Our mission is to deliver quick, efficient, and transparent hiring
          solutions that connect businesses with top-tier talent.
        </p>
      </div>
    </div>
  )
}

export default AboutUs
