import './hero.css'
import React, { useState, useEffect, useRef } from 'react'
import { heroContent } from '../../constants/heroContent'

const Hero = () => {
  // check if div is touching text
  const [cursorLoc, setCursorLoc] = useState({ x: 0, y: 0 })
  const [displayText, setDisplayText] = useState({ name: '', text: '' })
  const WindowHeight = window.innerHeight

  const logoImgRef = useRef([...Array(14)].map((_) => React.createRef()))

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (window.scrollY < WindowHeight * 0.8) {
        setCursorLoc({ x: event.clientX, y: event.clientY })
      }
    }
    window.addEventListener('mousemove', handleMouseMove)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [WindowHeight])

  useEffect(() => {
    const logoMovement = () => {
      logoImgRef.current.forEach((logoRef) => {
        if (logoRef.current) {
          if (logoRef.current.getBoundingClientRect().y > cursorLoc.y) {
            if (
              // if cursor upper left of image
              logoRef.current.getBoundingClientRect().left > cursorLoc.x
            ) {
              logoRef.current.style.transform =
                'translateY(-5%) translateX(-5%)'
            } else if (
              // if cursor upper right of image
              logoRef.current.getBoundingClientRect().right < cursorLoc.x
            ) {
              logoRef.current.style.transform = 'translateY(-5%) translateX(5%)'
            } else {
              // if cusor above image only
              logoRef.current.style.transform = 'translateY(-5%)'
            }
          }
          // if cursor is below image
          else {
            logoRef.current.style.transform = 'translateY(5%) translateX(-5%)'
          }
        }
      })
    }
    logoMovement()
    return () => logoMovement()
  }, [cursorLoc])

  useEffect(() => {
    const blurImage = (index) => {
      logoImgRef.current.forEach((ref, idx) => {
        if (index !== idx) {
          ref.current.style.filter = 'blur(2px)'
        } else {
          ref.current.style.filter = 'blur(0px)'
        }
      })
    }
    const unblurImage = () => {
      logoImgRef.current.forEach((ref) => {
        ref.current.style.filter = 'blur(0)'
      })
    }

    logoImgRef.current.forEach((ref, index) => {
      ref.current.addEventListener('mouseover', () => {
        blurImage(index)
      })
      ref.current.addEventListener('mouseleave', unblurImage)
    })

    return () => {
      logoImgRef.current.forEach((ref, index) => {
        ref.current.removeEventListener('mouseover', () => {
          blurImage(index)
        })
        ref.current.removeEventListener('mouseleave', unblurImage)
      })
    }
  })

  const handleMouseOverImage = (name, text) => {
    setDisplayText({ name, text })
  }

  const handleMouseOutImage = () => {
    setDisplayText({ name: '', text: '' })
  }

  return (
    <div className="hero__container">
      <div className="hero__headline__container">
        <p className="hero__headline">
          Incredible teams at every turn of your company’s growth.
        </p>
      </div>

      <div className="hero__logo__container">
        {heroContent.map(
          (logo, idx) =>
            idx < 14 && (
              <img
                key={logo.name + idx}
                className="hero__logo--image"
                src={logo.imgUrl}
                ref={logoImgRef.current[idx]}
                id={`logo__${idx}`}
                onMouseOver={() =>
                  handleMouseOverImage(
                    logo.name,
                    logo.liner,
                    logoImgRef.current[idx]
                  )
                }
                onMouseLeave={handleMouseOutImage}
                alt="logo"
              />
            )
        )}
      </div>

      <div className="hero__footer__container">
        <p>{displayText.name}</p>
        <p>{displayText.name && '•'}</p>
        <p>{displayText.text}</p>
      </div>
    </div>
  )
}

export default Hero
