const heroImage = {}

const importAll = (fileheroImage) => {
  fileheroImage.keys().forEach((key) => {
    const logoName = key.replace('./', '').split('.')[0]
    heroImage[logoName] = fileheroImage(key)
  })
}

importAll(require.context('./', false, /\.(png|jpe?g|svg|gif)$/))

export default heroImage
