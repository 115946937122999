const images = {}

const importAll = (fileImages) => {
  fileImages.keys().forEach((key) => {
    const imageName = key.replace('./', '').split('.')[0]
    images[imageName] = fileImages(key)
  })
}

importAll(require.context('./', false, /\.(png|jpe?g|svg|gif)$/))

export default images
