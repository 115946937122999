import heroImage from '../assets/heroImage'

export const heroContent = [
  {
    imgUrl: heroImage[1],
    name: 'Food',
    liner: 'Sustainability'
  },
  {
    imgUrl: heroImage[2],
    name: 'InsurTech',
    liner: 'Data Intelligence'
  },
  {
    imgUrl: heroImage[3],
    name: 'FinTech',
    liner: 'Programs'
  },
  {
    imgUrl: heroImage[4],
    name: 'BioTech',
    liner: 'Research'
  },
  {
    imgUrl: heroImage[5],
    name: 'LegalTech',
    liner: 'SaaS'
  },
  {
    imgUrl: heroImage[6],
    name: 'Mobile Gaming',
    liner: ''
  },
  {
    imgUrl: heroImage[7],
    name: 'BioTech',
    liner: 'Crypto'
  },
  {
    imgUrl: heroImage[8],
    name: 'PropTech',
    liner: 'Insurance'
  },
  {
    imgUrl: heroImage[9],
    name: 'BioTech',
    liner: 'Research'
  },
  {
    imgUrl: heroImage[10],
    name: 'EdTech',
    liner: 'Learning and Development'
  },
  {
    imgUrl: heroImage[11],
    name: 'Venture Capital',
    liner: 'Innovations'
  },
  {
    imgUrl: heroImage[12],
    name: 'PropTech',
    liner: 'Research'
  },
  {
    imgUrl: heroImage[13],
    name: 'Health & Wellness',
    liner: 'DTC'
  },
  {
    imgUrl: heroImage[14],
    name: 'Consulting',
    liner: 'Data'
  }
]
