import './logoMarquee.css'
import React from 'react'
import { logoMarquee } from '../../constants/logoMarquee'

const LogoMarquee = () => {
  return (
    <div className="logoMarquee__container">
      <div className="logoMarquee__content">
        {/* item set 1 */}
        {logoMarquee.map((logo, index) => (
          <div key={index} className="marquee-item">
            <img src={logo.imgUrl} alt="logo" />
          </div>
        ))}
        {/* item set 2 */}
        {logoMarquee.map((logo, index) => (
          <div key={index} className="marquee-item">
            <img src={logo.imgUrl} alt="logo" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default LogoMarquee
